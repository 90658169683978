import { useTranslation } from "react-i18next";
import "./Header.scss";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Dropdown } from "../../assets/images/dropdown.svg";
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as HeaderLogo } from "../../assets/images/header-logo.svg";

const Header = () => {
  const { t } = useTranslation();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const { headerBg } = useSelector((state) => state.headerColor);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 80);
      setPrevScrollPos(currentScrollPos);

      setIsMobileMenuOpen(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const bgColor = String(headerBg);

  return (
    <header
      className={`z-50 header h-[40px] lg:h-[80px] border-b border-[#42414A] ${bgColor} content-center ${visible ? "header-visible" : "header-hidden"
        }`}
    >
      <div className="flex flex-col lg:hidden">
        <div className="flex justify-between items-center">
          <a className="z-40" href="/">
            <HeaderLogo fill={isMobileMenuOpen ? '#1B1B1D' : (bgColor === 'bg-[#1B1B1D]') ? '#FFFFFF' : '#1B1B1D'} color={isMobileMenuOpen ? '#1B1B1D' : (bgColor === 'bg-[#1B1B1D]') ? '#FFFFFF' : '#1B1B1D'} className="menu-logo" />
          </a>
          <Menu
            transition
            onMenuChange={(open) => { setIsMobileMenuOpen(open.open); }}
            viewScroll="close"
            isOpen={isMobileMenuOpen}
            menuButton={({ open }) => (
              <MenuButton
                className={`menu-text z-40 ${isMobileMenuOpen ? 'text-[#1B1B1D]' : bgColor === 'bg-[#1B1B1D]' ? 'text-[#FFFFFF]' : 'text-[#1B1B1D]'}`}

              >
                {open ? "CLOSE" : "MENU"}
              </MenuButton>
            )}
          >
            <div className="flex flex-col burger-list">
              <HashLink to={"/references"}><MenuItem className='!text-[#563AFF]'>{t('HEADER.REFERENCES')}</MenuItem></HashLink>
              <HashLink to={"/about"}><MenuItem>{t('HEADER.ABOUT')}</MenuItem></HashLink>
              <HashLink to={"/services"}><MenuItem>{t('HEADER.SERVICES')}</MenuItem></HashLink>
              <HashLink to={"/references#partners"}><MenuItem className='!text-[#EDD526]'>{t('HEADER.PARTNERS')}</MenuItem></HashLink>
              {/* <HashLink to={"/blog"}><MenuItem>{t('HEADER.BLOG')}</MenuItem></HashLink> */}
            </div>
            <div className="divider"></div>
            <div className="flex social-list">
              <MenuItem href="https://www.instagram.com/namagency/">INSTAGRAM</MenuItem>
              <MenuItem href="https://www.linkedin.com/company/namagency/">LINKEDIN</MenuItem>
            </div>
          </Menu>
        </div>
      </div>
      <ul className={`h-full hidden lg:flex max-w-[614px] m-auto gap-10 items-center ${bgColor === 'bg-[#1B1B1D]' ? 'text-[#FFFFFF]' : 'text-[#1B1B1D]'}`}>
        <li>
          <HashLink to="about">{t('HEADER.ABOUT')}</HashLink>
        </li>
        <li>
          <HashLink to="services">{t('HEADER.SERVICES')}</HashLink>
        </li>
        <li>
          <HashLink to="/">
            <HeaderLogo className="ml-10 mr-10" fill={(bgColor === 'bg-[#1B1B1D]') ? '#FFFFFF' : '#1B1B1D'} color={(bgColor === 'bg-[#1B1B1D]') ? '#FFFFFF' : '#1B1B1D'} />
          </HashLink>
        </li>
        <li className="dropdown-div">
          <HashLink className={`${bgColor === 'bg-[#1B1B1D]' ? 'text-[#FFFFFF]' : 'text-[#563AFF]'}`} to="/references">
            <span className="flex gap-[6px]">
              {t('HEADER.REFERENCES')}
              <Dropdown color={`${bgColor === 'bg-[#1B1B1D]' ? 'text-[#FFFFFF]' : 'text-[#563AFF]'}`} className="self-center dropdown-logo" />
            </span>
            <div className="dropdown">
              <ul>
                <li><HashLink className={`${bgColor === 'bg-[#1B1B1D]' ? 'text-[#FFFFFF]' : 'text-[#563AFF]'}`} to={"/references#partners"}>{t("HEADER.PARTNERS")}</HashLink></li>
              </ul>
            </div>
          </HashLink>
        </li>
        {/* <li>
          <HashLink to="/blog">{t('HEADER.BLOG')}</HashLink>
        </li> */}
      </ul>
    </header>
  );
};
export default Header;
