import React, { useEffect, useRef } from 'react';
import './Beyond.scss';
import { useTranslation } from 'react-i18next';

const Beyond = () => {
    const spaceHolderRef = useRef(null);
    const horizontalRef = useRef(null);
    const stickyRef = useRef(null);
    
    const { t } = useTranslation();

    useEffect(() => {
        const calcDynamicHeight = () => {
            const vw = window.innerWidth;
            const vh = window.innerHeight;
            const objectWidth = horizontalRef.current.scrollWidth;
            return objectWidth - vw + vh + objectWidth / 3.7;
        };

        const handleScroll = () => {
            horizontalRef.current.style.transform = `translateX(-${stickyRef.current.offsetTop}px)`;
        };

        const handleResize = () => {
            spaceHolderRef.current.style.height = `${calcDynamicHeight()}px`;
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        handleResize();
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="beyond">
            <div ref={spaceHolderRef} className="space-holder">
                <div ref={stickyRef} className="sticky overflow-y-hidden">
                    <div className='flex flex-col px-4 lg:px-20'>
                        <span className='title'>{t("ABOUT.BEYOND.LEFT")}</span>
                        <span className='subtext'>{t("ABOUT.BEYOND.TITLE")}</span>
                    </div>
                    <div ref={horizontalRef} className="horizontal">
                        <div className='text-div'>
                            <span className='text'>{t("ABOUT.BEYOND.TEXT")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Beyond;
