import { configureStore } from "@reduxjs/toolkit";
import introSlice from "./features/intro/IntroSlice";
import headerColorSlice from "./features/headerColor/HeaderColor";

const store = configureStore({
  reducer: {
    intro: introSlice,
    headerColor: headerColorSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
