import './Agency.scss';
import Logo from '../../../../assets/images/agency-logo.png';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
const Agency = () => {

    const sections = useInView({ threshold: 0.2, triggerOnce: true });
    const sections2 = useInView({ threshold: 0.5, triggerOnce: true });

    const { t } = useTranslation();

    return (
        <section className="agency">
            <span className='title-left'>{t("ABOUT.AGENCY.LEFT")}</span>
            <div className='flex flex-col lg:flex-row gap-14 lg:gap-24 justify-between h-max mt-10 lg:mt-20'>
                <div ref={sections.ref} className='relative'>
                    <span className={`agency-title absolute -top-5 lg:-top-10 ${sections.inView ? 'fade-in-up' : 'fade-out'}`}>About</span>
                    <img className={`agency-logo ${sections.inView ? 'zoom-in delay-1s' : 'fade-out'}`} src={Logo} alt="Agency Logo" />
                </div>
                <div ref={sections2.ref} className='flex flex-col max-w-[573px] self-center'>
                    <span className={`title ${sections2.inView ? 'fade-in-up delay-2s' : 'fade-out'}`}>{t("ABOUT.AGENCY.TITLE")}</span>
                    <span className={`text ${sections2.inView ? 'fade-in-up delay-3s' : 'fade-out'}`}>{t("ABOUT.AGENCY.TEXT")}</span>
                </div>
            </div>
        </section>
    );
}

export default Agency;