import './Services.scss';
import AccordionSection from './sections/accordion/AccordionSection';
import Help from './sections/help/Help';

const Services = () => {
    return (
        <section className="services">
            <Help />
            <AccordionSection />
        </section>
    );
}

export default Services;