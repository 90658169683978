import { ReactComponent as Logo } from "../../../../assets/images/intro-logo.svg";
import "./Hero.scss";
import Marquee from "react-fast-marquee";
import { useState, useEffect } from "react";
import useChangeWidth from "../../../../hooks/useChangeWidth";
import { ReactComponent as AboutSvg } from "../../../../assets/images/about.svg";
import { ReactComponent as AboutSvgMobile } from "../../../../assets/images/about-mobile.svg";

const Hero = () => {

    const isSize = useChangeWidth(1024);
    const isBigger = useChangeWidth(1920);

    const [marqueeCount, setMarqueeCount] = useState(1);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    useEffect(() => {
        const calculatedMarqueeCount = Math.floor(vh / (isBigger ? 120 : (isSize ? 120 : 60)));

        setMarqueeCount(calculatedMarqueeCount);

    }, [vh, isSize, isBigger]);



    const [logoColorClass, setLogoColorClass] = useState('logo-yellow');

    const handleLogoHover = () => {
        const colorClasses = [
            'logo-yellow',
            'logo-purple',
            'logo-white',
            'logo-pink',
            'logo-orange'
        ];
        const randomIndex = Math.floor(Math.random() * colorClasses.length);
        setLogoColorClass(colorClasses[randomIndex]);
    };


    return (
        <section className="hero-slider flex flex-col bg-[#1B1B1D] my-2">

            {
                Array.from({ length: marqueeCount }).map((_, i) => (
                    <Marquee direction={i % 2 === 0 ? 'left' : 'right'} loop={0} speed={30} className={`mb-[5px]`} key={i} autoFill style={{
                        position: 'relative',
                        zIndex: '0',
                    }}>
                        {
                            i !== (Math.floor(marqueeCount / 2)) ?
                                <div className="flex">
                                    <Logo className={`mr-[10px] lg:mr-5 w-[88px] h-[48px] lg:w-[179px] lg:h-[98px] ${logoColorClass}`} fill="#26262B" color="#26262B" onMouseEnter={handleLogoHover} /></div>
                                :
                                <div className="flex">
                                    <AboutSvgMobile className="mr-5 block lg:hidden" />
                                    <AboutSvg className="hidden lg:block lg:mr-7" />
                                </div>
                        }
                    </Marquee>
                ))
            }

        </section>
    );
};
export default Hero;