import './AccordionSection.scss';
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import chevronDown from '../../../../assets/images/chevron.svg';
import AccordionCard from '../../../../components/accordionCard/AccordionCard';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

const AccordionSection = () => {


    const { t } = useTranslation();
    const itemRefs = useRef({});

    const scrollToItem = (id) => {
        setTimeout(() => {
            const item = itemRefs.current[id];
            if (item) {
                window.scrollTo({
                    top: item.offsetTop - 80,
                    behavior: 'smooth'
                });
            }
        }, 200);
    };

    const AccordionItem = ({ id, header, ...rest }) => (
        <Item
            {...rest}
            ref={(el) => (itemRefs.current[id] = el)}
            onClick={() => scrollToItem(id)}
            header={
                <>
                    {header}
                    <img className="chevron-down" src={chevronDown} alt="Chevron Down" />
                </>
            }
        />
    );
    return (
        <section className="accordion-section">
            <Accordion transition transitionTimeout={200}>
                <AccordionItem id="1" header="Brand Strategy">
                    <ul className='accordion-list'>
                        <AccordionCard title={t("SERVICES.ACCORDION.1.SUBTITLE")} text={t("SERVICES.ACCORDION.1.TEXT")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.1.SUBTITLE2")} text={t("SERVICES.ACCORDION.1.TEXT2")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(86, 58, 255, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.1.SUBTITLE3")} text={t("SERVICES.ACCORDION.1.TEXT3")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(255, 255, 255, 1)" bgColor="rgba(0, 0, 0, 0)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.1.SUBTITLE4")} text={t("SERVICES.ACCORDION.1.TEXT4")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(0, 0, 0, 0)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.1.SUBTITLE5")} text={t("SERVICES.ACCORDION.1.TEXT5")} textColor="rgba(27, 27, 29, 1)" titleColor="rgba(27, 27, 29, 1)" bgColor="rgba(237, 213, 38, 1)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.1.SUBTITLE6")} text={t("SERVICES.ACCORDION.1.TEXT6")} textColor="rgba(255, 255, 255, 1)" titleColor="rgba(255, 255, 255, 1)" bgColor="rgba(86, 58, 255, 1)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.1.SUBTITLE7")} text={t("SERVICES.ACCORDION.1.TEXT7")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(86, 58, 255, 1)" bgColor="rgba(0, 0, 0, 0.2)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.1.SUBTITLE8")} text={t("SERVICES.ACCORDION.1.TEXT8")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(255, 255, 255, 1)" bgColor="rgba(0, 0, 0, 0.2)" />
                    </ul>
                </AccordionItem>
                <AccordionItem id="2" header="Brand Identity">
                    <ul className='accordion-list'>
                        <AccordionCard title={t("SERVICES.ACCORDION.2.SUBTITLE")} text={t("SERVICES.ACCORDION.2.TEXT")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.2.SUBTITLE2")} text={t("SERVICES.ACCORDION.2.TEXT2")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(86, 58, 255, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.2.SUBTITLE3")} text={t("SERVICES.ACCORDION.2.TEXT3")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(255, 255, 255, 1)" bgColor="rgba(0, 0, 0, 0)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.2.SUBTITLE4")} text={t("SERVICES.ACCORDION.2.TEXT4")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(0, 0, 0, 0)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.2.SUBTITLE5")} text={t("SERVICES.ACCORDION.2.TEXT5")} textColor="rgba(27, 27, 29, 1)" titleColor="rgba(27, 27, 29, 1)" bgColor="rgba(237, 213, 38, 1)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.2.SUBTITLE6")} text={t("SERVICES.ACCORDION.2.TEXT6")} textColor="rgba(255, 255, 255, 1)" titleColor="rgba(255, 255, 255, 1)" bgColor="rgba(86, 58, 255, 1)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.2.SUBTITLE7")} text={t("SERVICES.ACCORDION.2.TEXT7")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(86, 58, 255, 1)" bgColor="rgba(0, 0, 0, 0.2)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.2.SUBTITLE8")} text={t("SERVICES.ACCORDION.2.TEXT8")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(255, 255, 255, 1)" bgColor="rgba(0, 0, 0, 0.2)" />
                    </ul>
                </AccordionItem>
                <AccordionItem id="3" header="Brand Activation">
                    <ul className='accordion-list'>
                        <AccordionCard title={t("SERVICES.ACCORDION.3.SUBTITLE")} text={t("SERVICES.ACCORDION.3.TEXT")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.3.SUBTITLE2")} text={t("SERVICES.ACCORDION.3.TEXT2")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(86, 58, 255, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.3.SUBTITLE3")} text={t("SERVICES.ACCORDION.3.TEXT3")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(255, 255, 255, 1)" bgColor="rgba(0, 0, 0, 0)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.3.SUBTITLE4")} text={t("SERVICES.ACCORDION.3.TEXT4")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(0, 0, 0, 0)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.3.SUBTITLE5")} text={t("SERVICES.ACCORDION.3.TEXT5")} textColor="rgba(27, 27, 29, 1)" titleColor="rgba(27, 27, 29, 1)" bgColor="rgba(237, 213, 38, 1)" />
                    </ul>
                </AccordionItem>
                <AccordionItem id="4" header="Community and Engagement">
                    <ul className='accordion-list'>
                        <AccordionCard title={t("SERVICES.ACCORDION.4.SUBTITLE")} text={t("SERVICES.ACCORDION.4.TEXT")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.4.SUBTITLE2")} text={t("SERVICES.ACCORDION.4.TEXT2")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(86, 58, 255, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.4.SUBTITLE3")} text={t("SERVICES.ACCORDION.4.TEXT3")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(255, 255, 255, 1)" bgColor="rgba(0, 0, 0, 0)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.4.SUBTITLE4")} text={t("SERVICES.ACCORDION.4.TEXT4")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(0, 0, 0, 0)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.4.SUBTITLE5")} text={t("SERVICES.ACCORDION.4.TEXT5")} textColor="rgba(27, 27, 29, 1)" titleColor="rgba(27, 27, 29, 1)" bgColor="rgba(237, 213, 38, 1)" />
                    </ul>
                </AccordionItem>
                <AccordionItem id="5" header="Digital Alchemy">
                    <ul className='accordion-list'>
                        <AccordionCard title={t("SERVICES.ACCORDION.5.SUBTITLE")} text={t("SERVICES.ACCORDION.5.TEXT")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.5.SUBTITLE2")} text={t("SERVICES.ACCORDION.5.TEXT2")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(86, 58, 255, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.5.SUBTITLE3")} text={t("SERVICES.ACCORDION.5.TEXT3")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(255, 255, 255, 1)" bgColor="rgba(0, 0, 0, 0)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.5.SUBTITLE4")} text={t("SERVICES.ACCORDION.5.TEXT4")} textColor="rgba(255, 255, 255, 0.4)" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(0, 0, 0, 0)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.5.SUBTITLE5")} text={t("SERVICES.ACCORDION.5.TEXT5")} textColor="rgba(27, 27, 29, 1)" titleColor="rgba(27, 27, 29, 1)" bgColor="rgba(237, 213, 38, 1)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.5.SUBTITLE6")} text={t("SERVICES.ACCORDION.5.TEXT6")} textColor="rgba(255, 255, 255, 1)" titleColor="rgba(255, 255, 255, 1)" bgColor="rgba(86, 58, 255, 1)" />
                    </ul>
                </AccordionItem>
                <AccordionItem id="6" header="People and Culture">
                    <ul className='accordion-list'>
                        <AccordionCard title={t("SERVICES.ACCORDION.6.SUBTITLE")} text={t("SERVICES.ACCORDION.6.TEXT")} textColor="white" titleColor="rgba(237, 213, 38, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.6.SUBTITLE2")} text={t("SERVICES.ACCORDION.6.TEXT2")} textColor="white" titleColor="rgba(86, 58, 255, 1)" bgColor="rgba(255, 255, 255, 0.04)" />
                        <AccordionCard title={t("SERVICES.ACCORDION.6.SUBTITLE3")} text={t("SERVICES.ACCORDION.6.TEXT3")} textColor="white" titleColor="white" bgColor="rgba(0, 0, 0, 0)" />
                    </ul>
                </AccordionItem>
            </Accordion>
        </section>
    );
}

export default AccordionSection;