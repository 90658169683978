import './Team.scss';
import Marquee from 'react-fast-marquee';
import { useInView } from 'react-intersection-observer';
import TeamImage from '../../../../assets/images/team.png';

const Team = () => {

    const sections = useInView({ threshold: 0.5, triggerOnce: true });

    return (
        <section className="team">
            <span className='left'>THE TEAM</span>
            <div ref={sections.ref} className='relative overflow-x-clip h-full'>
                <Marquee className={`${sections.inView ? 'fade-in-left-marquee delay-1s' : 'fade-out'}`} direction='right' autoFill speed={40} loop={0} style={{
                    background: 'rgba(237, 213, 38, 1)',
                    transform: 'rotate(8deg)',
                    position: 'absolute',
                    top: '0',
                    left: '-10%',
                    zIndex: '1',
                    width: '120%',
                    height: 'fit-content',
                    bottom: '0',
                    margin: 'auto',
                }}>
                    <span className='marquee-text'>&nbsp;THE TEAM</span>
                </Marquee>
                <Marquee className={`${sections.inView ? 'fade-in-right-marquee' : 'fade-out'}`} autoFill speed={40} loop={0} style={{
                    background: 'rgba(233, 203, 241, 1)',
                    transform: 'rotate(-8deg)',
                    position: 'absolute',
                    top: '0',
                    right: '-10%',
                    zIndex: '0',
                    width: '120%',
                    height: 'fit-content',
                    bottom: '0',
                    margin: 'auto',
                }}>
                    <span className='marquee-text2'>&nbsp;THE TEAM</span>
                </Marquee>
                <img src={TeamImage} alt='team' className={`team-image scale-0 delay-2s ${sections.inView ? 'card-scale' : 'fade-out'}`} />
            </div>
        </section>
    )

}

export default Team;