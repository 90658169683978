import React, { useEffect } from 'react';
import './Brand.scss';
import { setHeaderColorStatus } from '../../../../features/headerColor/HeaderColor';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-tabs/style/react-tabs.css';
import { HashLink } from 'react-router-hash-link';

const Brand = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            const brandSection = document.querySelector('.brand');
            if (brandSection) {
                const { top, bottom } = brandSection.getBoundingClientRect();
                const isMobile = window.innerWidth < 768;
                const brandTopThreshold = isMobile ? 40 : 80;
                const isPastBrandTop = top > brandTopThreshold;
                const isPastBrandBottom = bottom < 0;
                const isPastBrand = isPastBrandTop || isPastBrandBottom;
                dispatch(setHeaderColorStatus(isPastBrand ? 'bg-[#1B1B1D]' : 'bg-[#E9CBF1]'));
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch]);

    const handleClick = () => {
        dispatch(setHeaderColorStatus('bg-[#1B1B1D]'));
    }


    return (
        <section className='brand'>
            <span className='title block lg:hidden'>{t("HOME.BRAND.LEFT")}</span>
            <div className='flex justify-between max-w-[1920px] m-auto flex-col lg:flex-row items-center lg:items-start'>
                <div className='flex flex-col max-w-[400px] order-2 lg:order-1'>
                    <span className='title hidden lg:block'>{t("HOME.BRAND.LEFT")}</span>
                    <div className='flex flex-col'>
                        <span className='text'>{t("HOME.BRAND.TAB.TEXT")}</span>
                        <HashLink onClick={handleClick} to={'/services'} className="btn-23">
                            <span className="text-btn">{t("HOME.BRAND.BUTTON")}</span>
                            <span className="marquee" aria-hidden>{t("HOME.BRAND.BUTTON")}</span>
                        </HashLink>
                    </div>
                </div>
                <div className='flex flex-col order-1 lg:order-2 mb-10 lg:mb-0'>
                    <div className='flex items-center'>
                        <span className='num'>01</span>
                        <div className='flex flex-col num-text'>
                            <span>{t("HOME.BRAND.TAB.TITLE")}</span>
                            <span>{t("HOME.BRAND.TAB.TITLE12")}</span>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <span className='num'>02</span>
                        <div className='flex flex-col num-text'>
                            <span>{t("HOME.BRAND.TAB.TITLE2")}</span>
                            <span>{t("HOME.BRAND.TAB.TITLE22")}</span>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <span className='num'>03</span>
                        <div className='flex flex-col num-text'>
                            <span>{t("HOME.BRAND.TAB.TITLE3")}</span>
                            <span>{t("HOME.BRAND.TAB.TITLE32")}</span>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <span className='num'>04</span>
                        <div className='flex flex-col num-text'>
                            <span>{t("HOME.BRAND.TAB.TITLE4")}</span>
                            <span>{t("HOME.BRAND.TAB.TITLE42")}</span>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <span className='num'>05</span>
                        <div className='flex flex-col num-text'>
                            <span>{t("HOME.BRAND.TAB.TITLE5")}</span>
                            <span>{t("HOME.BRAND.TAB.TITLE52")}</span>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <span className='num'>06</span>
                        <div className='flex flex-col num-text'>
                            <span>{t("HOME.BRAND.TAB.TITLE6")}</span>
                            <span>{t("HOME.BRAND.TAB.TITLE62")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Brand;
