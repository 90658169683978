import { useSelector } from 'react-redux';
import Intro from './features/intro/Intro';
import RouteComponent from './routes';
import { useLocation } from 'react-router-dom';

function App() {


  const { introComplete } = useSelector((state) => state.intro);

  const location = useLocation();

  return (
    <div className="App">
      {
        location.pathname === "/" ?
          (introComplete ?
            (
              <><RouteComponent />
              </>) : <Intro />) : <RouteComponent />
      }
    </div>
  );
}

export default App;
