import './Help.scss';

const Help = () => {
    return (
        <section className="help">
            <div className='flex flex-col'>
                <span className='title'>How we help</span>
                <span className='text !text-[#E76738] lg:!font-semibold'>Discover the services provided
                    by Nam Agency -</span>
                <span className='text'>where belief molds branding. We fuse strategic innovation with creative proficiency to convert the fundamental beliefs of your organization into a distinctive brand that not only attracts attention but also represents a larger purpose. Uncover the ways our targeted services can propel your brand toward substantial growth and establish an authentic presence in the market.</span>
            </div>
        </section>
    );
}

export default Help;