import React, { useState, useEffect } from 'react';
import './Cursor.scss';

const Cursor = () => {
    const [position, setPosition] = useState({ x: -100, y: -100 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className="custom-cursor" style={{ left: `${position.x}px`, top: `${position.y}px` }}></div>
    );
};

export default Cursor;
