import './Leads.scss';
import Nage from '../../../../assets/images/nage.png';
import Asli from '../../../../assets/images/asli.png';
import Mihri from '../../../../assets/images/mihri.png';
import { ReactComponent as Arrow } from '../../../../assets/images/flip-arrow.svg';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

const Leads = () => {

    const sections = useInView({ threshold: 0.5, triggerOnce: true });


    const { t } = useTranslation();

    return (
        <section ref={sections.ref} className="leads">
            <span className='title'>{t("ABOUT.LEADS.LEFT")}</span>
            <div className='relative flex flex-col text'>
                <span>{t("ABOUT.LEADS.TEXT")}</span>
                <span>{t("ABOUT.LEADS.TEXT2")}</span>
                <div onClick={()=> window.open("https://www.linkedin.com/in/nagehang%C3%BCne%C5%9F/", "_blank")} className={`card hidden scale-0 lg:block origin-bottom-left delay-1s ${sections.inView ? 'card-scale' : 'fade-out'}`}>
                    <div className="content">
                        <div className="front">
                            <img className='image' src={Nage} alt='Nage' />
                        </div>
                        <div className="back">
                            <span>NAGEHAN GÜNEŞ OKMAN</span>
                            <Arrow className='absolute bottom-3 right-3 w-[40px] mr-5 mb-4 xl:mb-7' color='#42CD00' />
                        </div>
                    </div>
                </div>
                <div onClick={()=> window.open("https://www.linkedin.com/in/asli-caner/", "_blank")} className={`card hidden scale-0 lg:block origin-top-left delay-2s ${sections.inView ? 'card-scale' : 'fade-out'}`}>
                    <div className="content">
                        <div className="front">
                            <img className='image' src={Asli} alt='Asli' />
                        </div>
                        <div className="back">
                            <span>ASLI CANER</span>
                            <Arrow className='absolute bottom-6 right-6 w-[40px] mr-5 mb-4 xl:mb-7' color='#E76738' />
                        </div>
                    </div>
                </div>
                <div onClick={()=> window.open("https://www.linkedin.com/in/mihribantekmen/", "_blank")} className={`card hidden scale-0 lg:block origin-bottom-right delay-3s ${sections.inView ? 'card-scale' : 'fade-out'}`}>
                    <div className="content">
                        <div className="front">
                            <img className='image' src={Mihri} alt='Mihri' />
                        </div>
                        <div className="back">
                            <span>MİHRİBAN ERSİN</span>
                            <Arrow className='absolute bottom-6 right-6 w-[40px] mr-5 mb-4 xl:mb-7' color='#6167F7' />
                        </div>
                    </div>
                </div>
                <div onClick={()=> window.open("https://www.linkedin.com/in/nagehang%C3%BCne%C5%9F/", "_blank")} className={`image-div first block scale-0 origin-bottom-left lg:hidden delay-1s ${sections.inView ? 'card-scale' : 'fade-out'} `}>
                    <img className='image' src={Nage} alt='Nage' />
                </div>
                <div onClick={()=> window.open("https://www.linkedin.com/in/asli-caner/", "_blank")} className={`image-div second block scale-0 origin-top-left lg:hidden delay-2s ${sections.inView ? 'card-scale' : 'fade-out'} `}>
                    <img className='image' src={Asli} alt='Asli' />
                </div>
                <div onClick={()=> window.open("https://www.linkedin.com/in/mihribantekmen/", "_blank")} className={`image-div last block scale-0 origin-bottom-right lg:hidden delay-3s ${sections.inView ? 'card-scale' : 'fade-out'} `}>
                    <img className='image' src={Mihri} alt='Mihri' />
                </div>
            </div>
        </section>
    );
}

export default Leads;