import React from 'react';
import n from "../../assets/images/n.png";
import a from "../../assets/images/a.png";
import m from "../../assets/images/m.png";
import { ReactComponent as Logo } from "../../assets/images/intro-logo.svg";
import { setIntroStatus } from './IntroSlice';
import { useDispatch } from 'react-redux';

const Intro = () => {

    const dispatch = useDispatch();
    return (
        <>
            <div className="flex justify-center bg-[#1B1B1D]">
                <img className="h-screen fade-in-out delay-1s" src={n} alt="n" />
                <img className="h-screen fade-in-out delay-2s" src={a} alt="a" />
                <img className="h-screen fade-in-out delay-3s" src={m} alt="m" />
            </div>
            <Logo className='absolute top-0 right-0 left-0 bottom-0 m-auto delay-4s logo' fill='#563AFF' color='#563AFF' onAnimationEnd={()=>{dispatch(setIntroStatus(true))}} />
        </>
    );
};

export default Intro;
