import "./VideoSection.scss";
import videoSourceMobile from "../../../../assets/videos/main-mobile.mp4";
import videoSource from "../../../../assets/videos/main.mp4";
import useChangeWidth from "../../../../hooks/useChangeWidth";

const VideoSection = () => {
    const isSize = useChangeWidth(1024);
    

    return (
        <section className="video-section">
            {isSize ?
                <video key={videoSource} muted playsInline autoPlay loop className="video-bg">
                    <source src={videoSource} type="video/mp4" />
                </video>
                :
                <video key={videoSourceMobile} muted playsInline autoPlay loop className="video-bg">
                    <source src={videoSourceMobile} type="video/mp4" />
                </video>
            }
        </section>
    );
}

export default VideoSection;
