import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    headerBg: 'bg-[#1B1B1D]',
};

export const headerColor = createSlice({
    name: "header",
    initialState,
    reducers: {
        setHeaderColorStatus: (state, { payload }) => {
            state.headerBg = payload;
        },
    },
});

export const { setHeaderColorStatus } = headerColor.actions;

export default headerColor.reducer;