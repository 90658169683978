import React, { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Offerings.scss';
import { useTranslation } from 'react-i18next';

const Offerings = () => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.to('.text0', {
            x: '-10%',
            rotate: -10,
            duration: 0.85,
            scrollTrigger: {
                trigger: '.text0',
                scrub: true,
                start: 'top 20px'
            }
        });

        gsap.to('.text1', {
            x: '10%',
            rotate: 10,
            duration: 0.85,
            scrollTrigger: {
                trigger: '.text1',
                scrub: true,
                start: 'top 20px'
            }
        });

        gsap.to('.text2', {
            x: '-10%',
            rotate: -10,
            duration: 0.85,
            scrollTrigger: {
                trigger: '.text2',
                scrub: true,
                start: 'top 20px'
            }
        });

        gsap.to('.text3', {
            x: '10%',
            rotate: 10,
            duration: 0.85,
            scrollTrigger: {
                trigger: '.text3',
                scrub: true,
                start: 'top 20px'
            }
        });

        gsap.to('.text4', {
            x: '-10%',
            rotate: -10,
            duration: 0.85,
            scrollTrigger: {
                trigger: '.text4',
                scrub: true,
                start: 'top 20px'
            }
        });

        gsap.to('.text5', {
            x: '10%',
            rotate: 10,
            duration: 0.85,
            scrollTrigger: {
                trigger: '.text5',
                scrub: true,
                start: 'top 20px'
            }
        });
        gsap.to('.text6', {
            x: '-10%',
            rotate: -10,
            duration: 0.85,
            scrollTrigger: {
                trigger: '.text6',
                scrub: true,
                start: 'top 20px'
            }
        });
        gsap.to('.text7', {
            x: '10%',
            rotate: 10,
            duration: 0.85,
            scrollTrigger: {
                trigger: '.text7',
                scrub: true,
                start: 'top 20px'
            }
        });
    }, []);

    const { t } = useTranslation();

    return (
        <section className="offerings">
            <div className='flex flex-col gap-10 lg:gap-36'>
                <span className='text text0 text-left'>{t("ABOUT.OFFERINGS.TEXT")}</span>
                <span className='text text1 text-right self-end'>{t("ABOUT.OFFERINGS.TEXT2")}</span>
                <span className='text text2 text-left'>{t("ABOUT.OFFERINGS.TEXT3")}</span>
                <span className='text text3 text-right self-end'>{t("ABOUT.OFFERINGS.TEXT4")}</span>
                <span className='text text4 text-left'>{t("ABOUT.OFFERINGS.TEXT5")}</span>
                <span className='text text5 text-right self-end'>{t("ABOUT.OFFERINGS.TEXT6")}</span>
                <span className='text text6 text-left'>{t("ABOUT.OFFERINGS.TEXT7")}</span>
                <span className='text text7 text-right self-end'>{t("ABOUT.OFFERINGS.TEXT8")}</span>
            </div>
        </section>
    );
}

export default Offerings;
