import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {


    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className='flex flex-col'>
                <div className='flex justify-center first-section'>
                    <ul className='first-list'>
                        <li><HashLink to='/about'>{t("FOOTER.ABOUT")}</HashLink></li>
                        <li><HashLink to='/services'>{t("FOOTER.SERVICES")}</HashLink></li>
                        <li><HashLink to='/references'>{t("FOOTER.REFERENCES")}</HashLink></li>
                        <li><HashLink to={"/references#partners"}>{t("FOOTER.PARTNERS")}</HashLink></li>
                        {/* <li><HashLink to='/blog'>{t("FOOTER.BLOG")}</HashLink></li> */}
                        <li><a href='mailto:hello@namagency.xyz'>{t("FOOTER.CONTACT")}</a></li>
                    </ul>
                </div>
                <div className='flex justify-between second-section'>
                    <ul className='second-list'>
                        <li><HashLink to='https://www.instagram.com/namagency/'>INSTAGRAM</HashLink></li>
                        <li><HashLink to='https://www.linkedin.com/company/namagency/'>LINKEDIN</HashLink></li>
                    </ul>
                    <span className='text'>Copyright 2024</span>
                </div>
            </div>
        </footer>
    )
}
export default Footer;
