import './Learn.scss';
import { useTranslation } from 'react-i18next';
import Hello from '../../../../assets/videos/hello.mp4';

const Learn = () => {

    const { t } = useTranslation();

    return (
        <section className="learn">
            <div className='flex flex-col'>
                <span className='title'>{t("HOME.LEARN.LEFT")}</span>
                <div className='flex justify-between flex-col lg:flex-row'>
                    <div className='flex flex-col order-2 lg:order-1'>
                        <span className='text'>{t("HOME.LEARN.TEXT")}</span>
                        <div className='flex flex-col'>
                            <span className='hello'>{t("HOME.LEARN.HELLO")}</span>
                            <a href='mailto:hello@namagency.xyz' className='mail'>{t("HOME.LEARN.MAIL")}</a>
                        </div>
                    </div>
                    <video key={Hello} autoPlay playsInline loop muted className='card-container order-1 lg:order-2'>
                        <source src={Hello} type="video/mp4" />
                    </video>
                </div>
            </div>
        </section>
    )

};
export default Learn;