import "./AccordionCard.scss";

const AccordionCard = ({ title, text, titleColor, textColor, bgColor }) => {

    return (
        <div className="accordion-card" style={{ backgroundColor: bgColor }}>
            <h3 className="accordion-card-title" style={{ color: titleColor }}>{title}</h3>
            <p className="accordion-card-text" style={{ color: textColor }}>{text}</p>
        </div>
    );
}

export default AccordionCard;