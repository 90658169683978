import './References.scss';
import Marquee from 'react-fast-marquee';
import YVentures from '../../assets/images/yventures.png';
import YTech from '../../assets/images/ytech.png';
import Dzd from '../../assets/images/dzd.png';
import XSights from '../../assets/images/xsights.png';
import EStar from '../../assets/images/estar.png';
import TRWin from '../../assets/images/trwin.png';
import Ogansia from '../../assets/images/ogansia.png';
import Molecure from '../../assets/images/molecure.png';
import Meral from '../../assets/images/meral.png';
import Guvensan from '../../assets/images/guvensan.png';
import Alef from '../../assets/images/alef.png';
import Kolektif from '../../assets/images/kolektif.png';
import May from '../../assets/images/may.png';
import Sebnem from '../../assets/images/sebnem.png';
import Rna from '../../assets/images/rna.png';
import Piks from '../../assets/images/piks.png';
import Ofmark from '../../assets/images/ofmark.png';
import Tutumlu from '../../assets/images/tutumlu.png';
import Pala from '../../assets/images/pala.png';
import Culture from '../../assets/images/culture.png';
import No44 from '../../assets/images/no44.png';
import Kadin from '../../assets/images/kadin.png';
import Cesme from '../../assets/images/cesme.png';
import Binyaprak from '../../assets/images/binyaprak.png';
import Ted from '../../assets/images/ted.png';
import Yeniden from '../../assets/images/yeniden.png';
import Ic from '../../assets/images/ic.png';
import Sakli from '../../assets/images/sakli.png';
import Ibrahim from '../../assets/images/ibrahim.png';
import Looyti from '../../assets/images/looyti.png';
import Pirix from '../../assets/images/pirix.png';
import Pofft from '../../assets/images/pofft.png';
import Coven from '../../assets/images/coven.png';
import CVentures from '../../assets/images/cventures.png';
import Colendi from '../../assets/images/colendi.png';
import Menkul from '../../assets/images/menkul.png';
import Sigorta from '../../assets/images/sigorta.png';
import Erkek from '../../assets/images/erkek.png';
import Budyboo from '../../assets/images/budyboo.png';
import { useInView } from "react-intersection-observer";
import Dessera from '../../assets/images/dessera.png';
import Forbes from '../../assets/images/forbes.png';
import ColendiBank from '../../assets/images/colendibank.png'

const References = () => {

    const sections = useInView({ threshold: 0.2, triggerOnce: true });
    const sections2 = useInView({ threshold: 0.6, triggerOnce: true });
    const sections3 = useInView({ threshold: 0.7, triggerOnce: true });

    return (
        <section className='references'>
            <div ref={sections.ref} className='ref-screen'>
                <span className='left justify-self-start'>REFERENCES</span>
                <div className='relative xl:self-end xl:mt-[80px] overflow-hidden w-full'>
                    <div className='flex flex-col xl:flex-row xl:justify-center'>
                        <span className={`title ${sections.inView ? "fade-in-left" : "fade-out"}`}>we love&nbsp;</span>
                        <span className={`title ${sections.inView ? "delay-1-5s fade-in-up" : "fade-out"}`}>to leave a mark</span>
                    </div>
                    <Marquee className={`flex xl:!hidden ${sections.inView ? 'delay-3s fade-in' : 'fade-out'}`} autoFill speed={30}>
                        <div className='flex gap-10 mt-10 justify-center mr-10 h-12'>
                            <img style={{ height: '61px' }} src={YVentures} alt='YVentures' />
                            <img style={{ height: '61px' }} src={YTech} alt='YTech' />
                            <img style={{ height: '61px' }} src={Dzd} alt='Dzd' />
                            <img src={XSights} alt='XSights' />
                        </div>
                    </Marquee>
                    <Marquee className={`flex xl:!hidden ${sections.inView ? 'delay-3s fade-in' : 'fade-out'}`} direction='right' autoFill speed={30}>
                        <div className='flex gap-10 mt-5 justify-center mr-10 h-12'>
                            <img src={EStar} alt='EStar' />
                            <img src={TRWin} alt='TRWin' />
                            <img src={Binyaprak} alt='Binyaprak' />
                            <img src={Cesme} alt='Cesme' />
                            <img src={Forbes} alt='Forbes' />
                        </div>
                    </Marquee>
                    <Marquee className={`flex xl:!hidden ${sections.inView ? 'delay-3s fade-in' : 'fade-out'}`} autoFill speed={30}>
                        <div className='flex gap-10 mt-5 justify-center mr-10 h-12'>
                            <img src={Ic} alt='Ic' />
                            <img src={Menkul} alt='Menkul' />
                            <img src={Sigorta} alt='Sigorta' />
                            <img src={Yeniden} alt='Yeniden' />
                            <img src={Kadin} alt='Kadin' />
                            <img src={Dessera} alt='Dessera' />
                        </div>
                    </Marquee>
                    <Marquee className={`flex xl:!hidden ${sections.inView ? 'delay-3s fade-in' : 'fade-out'}`} autoFill speed={30}>
                        <div className='flex gap-10 mt-5 justify-center mr-10 h-12'>
                            <img src={Colendi} alt='Colendi' />
                            <img src={ColendiBank} alt='Kadin' />
                            <img src={Looyti} alt='Looyti' />
                        </div>
                    </Marquee>
                </div>
                <div className={`hidden ref-slide xl:flex gap-4 mt-[120px] justify-center self-start ${sections.inView ? "delay-3s fade-in" : "fade-out"}`}>
                    <img style={{ height: '61px' }} src={YVentures} alt='YVentures' />
                    <div className='slide-div' />
                    <img style={{ height: '61px' }} src={YTech} alt='YTech' />
                    <div className='slide-div' />
                    <img style={{ height: '61px' }} src={Dzd} alt='Dzd' />
                    <div className='slide-div' />
                    <img src={XSights} alt='XSights' />
                </div>
                <div className={`hidden ref-slide xl:flex gap-4 mt-[50px] justify-center self-start ${sections.inView ? "delay-3s fade-in" : "fade-out"}`}>
                    <img src={EStar} alt='EStar' />
                    <div className='slide-div' />
                    <img src={TRWin} alt='TRWin' />
                    <div className='slide-div' />
                    <img src={Binyaprak} alt='Binyaprak' />
                    <div className='slide-div' />
                    <img src={Cesme} alt='Cesme' />
                    <div className='slide-div' />
                    <img className='!h-[111px]' src={Forbes} alt='Forbes' />
                </div>
                <div className={`hidden ref-slide xl:flex gap-4 mt-[50px] justify-center self-start ${sections.inView ? "delay-3s fade-in" : "fade-out"}`}>
                    <img src={Ic} alt='Ic' />
                    <div className='slide-div' />
                    <img src={Menkul} alt='Menkul' />
                    <div className='slide-div' />
                    <img src={Sigorta} alt='Sigorta' />
                    <div className='slide-div' />
                    <img src={Yeniden} alt='Yeniden' />
                    <div className='slide-div' />
                    <img src={Kadin} alt='Kadin' />
                    <div className='slide-div' />
                    <img src={Dessera} alt='Dessera' />
                </div>
                <div className={`hidden ref-slide xl:flex gap-4 mt-[50px] justify-center self-start ${sections.inView ? "delay-3s fade-in" : "fade-out"}`}>
                    <img src={Colendi} alt='Colendi' />
                    <div className='slide-div' />
                    <img src={ColendiBank} alt='ColendiBank' />
                    <div className='slide-div' />
                    <img src={Looyti} alt='Looyti' />
                </div>
            </div>
            <div ref={sections2.ref} className='ref-screen'>
                <div className='relative xl:self-end overflow-hidden w-full'>
                    <div className='flex flex-col xl:flex-row xl:justify-center'>
                        <span className={`title ${sections2.inView ? 'fade-in-up' : 'fade-out'}`}>always a part of&nbsp;</span>
                        <div className='flex self-center'>
                            <span className={`title ${sections2.inView ? 'fade-in-up' : 'fade-out'}`}>our</span>
                            <span className={`title ${sections2.inView ? 'fade-in-up' : 'fade-out'}`}>&nbsp;legacy</span>
                        </div>
                    </div>
                    <Marquee className={`flex xl:!hidden ${sections2.inView ? "delay-1-5s fade-in" : "fade-out"}`} autoFill speed={30}>
                        <div className='flex gap-10 mt-10 justify-center mr-10 h-12 grayscale'>
                            <img src={Sebnem} alt='Sebnem' />
                            <img src={Alef} alt='Alef' />
                            <img src={Tutumlu} alt='Tutumlu' />
                            <img src={Pala} alt='Pala' />
                        </div>
                    </Marquee>
                    <Marquee className={`flex xl:!hidden ${sections2.inView ? "delay-1-5s fade-in" : "fade-out"}`} direction='right' autoFill speed={30}>
                        <div className='flex gap-10 mt-5 justify-center mr-10 h-12 grayscale'>
                            <img src={Piks} alt='Piks' />
                            <img src={Ted} alt='Ted' />
                            <img src={Culture} alt='Culture' />
                            <img src={Erkek} alt='Erkek' />
                        </div>
                    </Marquee>
                    <Marquee className={`flex xl:!hidden ${sections2.inView ? "delay-1-5s fade-in" : "fade-out"}`} autoFill speed={30}>
                        <div className='flex gap-10 mt-5 justify-center mr-10 h-12 grayscale'>
                            <img src={Guvensan} alt='Guvensan' />
                            <img src={Ibrahim} alt='Ibrahim' />
                            <img src={Sakli} alt='Sakli' />
                            <img src={Kolektif} alt='Kolektif' />
                            <img src={Molecure} alt='Molecure' />
                        </div>
                    </Marquee>
                    <Marquee className={`flex xl:!hidden ${sections2.inView ? "delay-1-5s fade-in" : "fade-out"}`} direction='right' autoFill speed={30}>
                        <div className='flex gap-10 mt-5 justify-center mr-10 h-12 grayscale'>
                            <img src={Meral} alt='Meral' />
                            <img src={No44} alt='No44' />
                            <img src={Ofmark} alt='Ofmark' />
                            <img src={Ogansia} alt='Ogansia' />
                            <img src={May} alt='May' />
                            <img src={Rna} alt='Rna' />
                        </div>
                    </Marquee>
                </div>
                <div className={`hidden ref-slide xl:flex gap-4 mt-[120px] justify-center self-start ${sections2.inView ? 'delay-1-5s fade-in-up-lum' : 'fade-out'} `}>
                    <img className='mix-blend-luminosity' src={Sebnem} alt='Sebnem' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Alef} alt='Alef' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Tutumlu} alt='Tutumlu' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Pala} alt='Pala' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Rna} alt='Rna' />
                </div>
                <div className={`hidden ref-slide xl:flex gap-4 mt-[50px] justify-center self-start ${sections2.inView ? 'delay-1-5s fade-in-up-lum' : 'fade-out'} `}>
                    <img className='mix-blend-luminosity' src={Piks} alt='Piks' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Ted} alt='Ted' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Culture} alt='Culture' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Erkek} alt='Erkek' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Molecure} alt='Molecure' />

                </div>
                <div className={`hidden ref-slide xl:flex gap-4 mt-[50px] justify-center self-start ${sections2.inView ? 'delay-1-5s fade-in-up-lum' : 'fade-out'} `}>
                    <img className='mix-blend-luminosity' src={Guvensan} alt='Guvensan' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Ibrahim} alt='Ibrahim' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Sakli} alt='Sakli' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Kolektif} alt='Kolektif' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Meral} alt='Meral' />

                </div>
                <div className={`hidden ref-slide xl:flex gap-4 mt-[50px] justify-center self-start ${sections2.inView ? 'delay-1-5s fade-in-up-lum' : 'fade-out'} `}>
                    <img className='mix-blend-luminosity' src={No44} alt='No44' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Ofmark} alt='Ofmark' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={Ogansia} alt='Ogansia' />
                    <div className='slide-div' />
                    <img className='mix-blend-luminosity' src={May} alt='May' />
                </div>
            </div>
            <div id='partners' ref={sections3.ref} className='ref-screen'>
                <span className='left justify-self-start'>PARTNERS</span>
                <div className='relative xl:self-end xl:mt-[80px] overflow-hidden w-full'>
                    <div className='flex flex-col xl:flex-row xl:justify-center'>
                        <span className={`title ${sections3.inView ? "fade-in-up" : "fade-out"}`}>united, we empower excellence</span>
                    </div>
                    <Marquee className={`flex xl:!hidden ${sections3.inView ? 'delay-1-5s fade-in' : 'fade-out'}`} autoFill speed={30}>
                        <div className='flex gap-10 mt-10 justify-center mr-10 h-12 h-'>
                            <img src={Budyboo} alt='Budyboo' />
                            <img src={Looyti} alt='Looyti' />
                            <img src={Pirix} alt='Pirix' />
                            <img src={Pofft} alt='Pofft' />
                            <img src={Coven} alt='Coven' />
                        </div>
                    </Marquee>
                    <Marquee className={`flex xl:!hidden ${sections3.inView ? 'delay-1-5s fade-in' : 'fade-out'}`} direction='right' autoFill speed={30}>
                        <div className='flex gap-10 mt-5 justify-center mr-10 h-12'>
                            <img src={CVentures} alt='CVentures' />
                            <img src={Colendi} alt='Colendi' />
                        </div>
                    </Marquee>
                </div>
                <div className={`hidden ref-slide xl:flex gap-4 mt-[120px] justify-center self-start ${sections3.inView ? "delay-1-5s fade-in" : "fade-out"}`}>
                    <img src={Budyboo} alt='Budyboo' />
                    <div className='slide-div' />
                    <img src={Looyti} alt='Looyti' />
                    <div className='slide-div' />
                    <img src={Pirix} alt='Pirix' />
                    <div className='slide-div' />
                    <img src={Pofft} alt='Pofft' />
                    <div className='slide-div' />
                    <img src={Coven} alt='Coven' />
                </div>
                <div className={`hidden ref-slide xl:flex gap-4 mt-[50px] justify-center self-start ${sections3.inView ? "delay-1-5s fade-in" : "fade-out"}`}>
                    <img src={CVentures} alt='CVentures' />
                    <div className='slide-div' />
                    <img src={Colendi} alt='Colendi' />
                </div>
            </div>
        </section>
    )
}

export default References;