import React, { useEffect } from 'react';
import './Craft.scss';
import { setHeaderColorStatus } from '../../../../features/headerColor/HeaderColor';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Craft = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            const yellowSection = document.querySelector('.screen.yellow');
            if (yellowSection) {
                const { top, bottom } = yellowSection.getBoundingClientRect();
                const isMobile = window.innerWidth < 768;
                const yellowTopThreshold = isMobile ? 40 : 80;
                const isPastYellowTop = top > yellowTopThreshold;
                const isPastYellowBottom = bottom < 0;
                const isPastYellow = isPastYellowTop || isPastYellowBottom;
                dispatch(setHeaderColorStatus(isPastYellow ? 'bg-[#1B1B1D]' : 'bg-[#EDD526]'));
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch]);


    return (
        <div className="App">
            <div className="craft">
                <div className="screen black">
                    <div className="content text-[#FFFFFF]">
                        <span>{t("ABOUT.CRAFT.TITLE")}</span>
                    </div>
                </div>
                <div className="screen yellow">
                    <div className="content text-[#1B1B1D]">
                        <span>{t("ABOUT.CRAFT.TITLE2")}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Craft;
