import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import "./assets/scss/global.scss";
import { Provider } from 'react-redux';
import store from './store';
import { ParallaxProvider } from 'react-scroll-parallax';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './hooks/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));

const userLanguage = navigator.language.split('-')[0];
const language = localStorage.getItem('language');

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require('./locales/en/translation.json')
    },
    tr: {
      translation: require('./locales/tr/translation.json')
    }
  },
  lng: language ? language : userLanguage === 'tr' ? 'tr' : 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
root.render(
  <ParallaxProvider>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>
  </ParallaxProvider>
);

