import './About.scss';
import Agency from './sections/agency/Agency';
import Beyond from './sections/beyond/Beyond';
import Blending from './sections/blending/Blending';
import Craft from './sections/craft/Craft';
import Hero from './sections/hero/Hero';
import Offerings from './sections/offerings/Offerings';
import Leads from './sections/leads/Leads';
import Cursor from '../../components/cursor/Cursor';
import { useState } from 'react';
import Team from './sections/team/Team';

const About = () => {

    const [showCursor, setShowCursor] = useState(false);

    const handleMouseEnter = () => {
        setShowCursor(true);
    };

    const handleMouseLeave = () => {
        setShowCursor(false);
    };


    return (
        <section className="about"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
            {showCursor && <Cursor />}
            <Hero />
            <Agency />
            <Offerings />
            <Leads />
            <Team />
            <Beyond />
            <Craft />
            <Blending />
        </section>
    );
}

export default About;