import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Services from "./pages/services/Services";
import References from "./pages/references/References";
import { useEffect } from "react";
import { setHeaderColorStatus } from "./features/headerColor/HeaderColor";
import { useDispatch } from "react-redux";

const routesConfig = [
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/about',
        element: <About />,
    },
    {
        path: '/services',
        element: <Services />,
    },
    {
        path: '/references',
        element: <References />,
    }
    // {
    //     path: 'blog',
    //     element: <Blog />,
    // },
    // {
    //     path: 'blog/:id',
    //     element: <BlogDetail />,
    // }
];

const RouteComponent = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHeaderColorStatus('bg-[#1B1B1D]'));
    }, [location, dispatch]);

    return (
        <>
            <Header />
            <Routes location={location}>
                {routesConfig.map((route, i) => (
                    <Route key={i} path={route.path} element={route.element} />
                ))}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
        </>
    );
}

export default RouteComponent;
