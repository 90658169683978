import React, { useEffect } from 'react';
import './We.scss';
import { setHeaderColorStatus } from '../../../../features/headerColor/HeaderColor';
import { useDispatch } from 'react-redux';
import Marquee from 'react-fast-marquee';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import useChangeWidth from '../../../../hooks/useChangeWidth';
import { HashLink } from 'react-router-hash-link';

const We = () => {
    const dispatch = useDispatch();
    const [isPastWe, setIsPastWe] = useState(false);
    const [isPastBrand, setIsPastBrand] = useState(false);
    const { t } = useTranslation();
    const isSize = useChangeWidth(1024);
    const sections = useInView({ threshold: 0.5, triggerOnce: true });

    useEffect(() => {
        const handleScroll = () => {
            const weSection = document.querySelector('.we');
            const brandSection = document.querySelector('.brand');
            if (weSection && brandSection) {
                const { top: weTop, bottom: weBottom } = weSection.getBoundingClientRect();
                const { top: brandTop } = brandSection.getBoundingClientRect();
                const isMobile = window.innerWidth < 768;
                const topThreshold = isMobile ? 40 : 80;
                const isPastWeTop = weTop > topThreshold;
                const isPastWeBottom = weBottom < 0;
                const isPastWeSection = isPastWeTop || isPastWeBottom;
                const isPastBrandTop = brandTop < 0;

                setIsPastWe(isPastWeSection);
                setIsPastBrand(isPastBrandTop);

                if (!isPastBrand) {
                    // Dispatch color change based on the flag
                    dispatch(setHeaderColorStatus(isPastWe ? 'bg-[#1B1B1D]' : 'bg-[#EDD526]'));
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch, isPastBrand, isPastWe]);

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleWindowResize);

    const spansSlow = document.querySelectorAll('.spanSlow');
    const spansFast = document.querySelectorAll('.spanFast');

    let width = window.innerWidth;

    function handleMouseMove(e) {
        let normalizedPosition = e.pageX / (width / 2) - 1;
        let speedSlow = (isSize ? 200 : 100) * normalizedPosition;
        let speedFast = 200 * normalizedPosition;
        spansSlow.forEach((span) => {
            span.style.transform = `translate(${speedSlow}px)`;
        });
        spansFast.forEach((span) => {
            span.style.transform = `translate(${speedFast}px)`
        })
    }
    function handleWindowResize() {
        width = window.innerWidth;
    }

    const handleClick = () => {
        dispatch(setHeaderColorStatus('bg-[#1B1B1D]'));
    }


    return (
        <section className='we'>
            <div className='flex flex-col we-middle'>
                <span className='top-text'>{t("HOME.WE.LEFT")}</span>
                <div className='flex flex-col'>
                    {/* <div className='flex flex-col title'>
                        <span>{t("HOME.WE.TITLE")}</span>
                        <span>{t("HOME.WE.TITLE2")}</span>
                    </div> */}
                    <div className="wrap">
                        <div className="line">
                            <div className="left">
                                <div className="content">
                                    <span className="spanSlow">SHAPING THE</span>
                                </div>
                            </div>
                            <div className="right">
                                <div className="content">
                                    <span className="spanSlow">SHAPING THE</span>
                                </div>
                            </div>
                        </div>
                        <div className="line">
                            <div className="left">
                                <div className="content">
                                    <span className="spanSlow">DIGITAL WORLD</span>
                                </div>
                            </div>
                            <div className="right">
                                <div className="content">
                                    <span className="spanSlow">DIGITAL WORLD</span>
                                </div>
                            </div>
                        </div>
                        <div className="line">
                            <div className="left">
                                <div className="content">
                                    <span className="spanSlow">WITH OUR</span>
                                </div>
                            </div>
                            <div className="right">
                                <div className="content">
                                    <span className="spanSlow">WITH OUR</span>
                                </div>
                            </div>
                        </div>
                        <div className="line">
                            <div className="left">
                                <div className="content">
                                    <span className="spanSlow">CREATIVE VIBES</span>
                                </div>
                            </div>
                            <div className="right">
                                <div className="content">
                                    <span className="spanSlow">CREATIVE VIBES</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-x-10 lg:gap-x-20 justify-center'>
                        <div className='flex flex-col flex-1 lg:flex-none'>
                            <span className='sub-text'>{t("HOME.WE.TEXT")}</span>
                            <span className='sub-text-bold'>{t("HOME.WE.TEXT2")}</span>
                        </div>
                        <div className='flex flex-col flex-1 lg:flex-none'>
                            <span className='sub-text'>{t("HOME.WE.TEXT3")}</span>
                            <span className='sub-text-bold'>{t("HOME.WE.TEXT4")}</span>
                        </div>
                    </div>
                    <HashLink onClick={handleClick} to={'/about'} className="btn-23">
                        <span className="text">{t("HOME.WE.BUTTON")}</span>
                        <span className="marquee" aria-hidden>{t("HOME.WE.BUTTON")}</span>
                    </HashLink>
                </div>
            </div>
            <div ref={sections.ref} className='relative overflow-x-clip'>
                <Marquee className={`${sections.inView ? 'fade-in-left-marquee delay-1s' : 'fade-out'}`} direction='right' autoFill speed={40} loop={0} style={{
                    background: 'rgba(231, 103, 56, 1)',
                    transform: 'rotate(8deg)',
                    position: 'absolute',
                    top: '0',
                    left: '-10%',
                    zIndex: '1',
                    width: '120%',
                }}>
                    <span className='marquee-text'>&nbsp;{t("HOME.WE.MARQUEE")}</span>
                </Marquee>
                <Marquee className={`${sections.inView ? 'fade-in-right-marquee' : 'fade-out'}`} autoFill speed={40} loop={0} style={{
                    background: 'rgba(86, 58, 255, 1)',
                    transform: 'rotate(-8deg)',
                    position: 'absolute',
                    top: '0',
                    right: '-10%',
                    zIndex: '0',
                    width: '120%',
                }}>
                    <span className='marquee-text2'>&nbsp;{t("HOME.WE.MARQUEE2")}</span>
                </Marquee>
            </div>
        </section>
    )
}
export default We;
