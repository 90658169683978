import { useTranslation } from 'react-i18next';
import './Believe.scss';

const Believe = () => {

    const { t } = useTranslation();


    return (
        <section className='believe'>
            <span className='top-text'>{t("HOME.BELIEVE.LEFT")}</span>
            <div className='flex flex-col believe-middle'>
                <span className='title'>{t("HOME.BELIEVE.TITLE")}</span>
                <span className='text'>{t("HOME.BELIEVE.TITLE2")}</span>
            </div>
        </section>
    )

}
export default Believe;