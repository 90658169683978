import { useTranslation } from 'react-i18next';
import './Blending.scss';
import { useInView } from 'react-intersection-observer';

const Blending = () => {


    const sections = useInView({ threshold: 0.3, triggerOnce: true });
    const sections2 = useInView({ threshold: 0.6, triggerOnce: true });
    const sections3 = useInView({ threshold: 0.9, triggerOnce: true });

    const { t } = useTranslation();

    return (
        <section className="blending">
            <div className='flex flex-col gap-10 lg:gap-36'>
                <span ref={sections.ref} className={`text text-left ${sections.inView ? 'fade-in-left' : 'fade-out'}`}>{t("ABOUT.BLEND.TEXT")}</span>
                <span ref={sections2.ref} className={`text text-right self-end delay-1s ${sections2.inView ? 'fade-in-right' : 'fade-out'}`}>{t("ABOUT.BLEND.TEXT2")}</span>
                <div ref={sections3.ref} className={`text text-left flex flex-col delay-2s ${sections3.inView ? 'fade-in-left' : 'fade-out'}`}><span>{t("ABOUT.BLEND.TEXT3")}</span>
                    <span>{t("ABOUT.BLEND.TEXT4")}</span>
                    <span className='text-[#E76738] font-bold'>{t("ABOUT.BLEND.TEXT5")}</span>
                </div>
            </div>
        </section>
    );
}

export default Blending;