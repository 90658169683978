import Believe from "./sections/believe/Believe";
import Brand from "./sections/brand/Brand";
import Learn from "./sections/learn/Learn";
import VideoSection from "./sections/video-section/VideoSection";
import We from "./sections/we/We";

const Home = () => {
    return (
        <section className="hero">
            <VideoSection />
            <We />
            <Believe />
            <Brand />
            <Learn />
        </section>
    );
}
export default Home;